import React, { useRef } from 'react'
import dynamic from 'next/dynamic'

import { Props } from 'components/formElements/richText/QuillWrapper'
import FadeInOut from 'components/transitions/FadeInOut'

import styles from './editor.module.scss'

const Editor = (props: Props) => {
  const Loading = () => <div className={styles.loadingText}>{props.value}</div>

  // prettier-ignore
  // Cannot import dynmically this way otherwise every time you rerender the page, it'll refire the dynamic and rerender the component from scratch
  // Set as a ref so we never reimport the dynamic component
  const dynamicQuillRef = useRef(dynamic(() => import('components/formElements/richText/QuillWrapper'), { ssr: false, loading: () => <Loading /> }))

  const DynamicQuill = dynamicQuillRef.current

  return (
    <FadeInOut show>
      <DynamicQuill {...props} />
    </FadeInOut>
  )
}

export default Editor
