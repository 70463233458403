import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import UserService from 'services/user.service'

import PhatButton from 'components/formElements/PhatButton'

type Props = {
  followed: boolean
  followId: number | string
  compact?: boolean
  className?: string
}

const FollowButton = ({ followed, followId, compact, className }: Props) => {
  const [{ tbId: userId }] = useCookies(['tbId'])

  const [loading, setLoading] = useState(false)
  const [localFollowed, setLocalFollowed] = useState(followed)

  const handleToggleFollow = () => {
    setLoading(true)

    UserService.follow(followId, localFollowed)
      .then(data => data.status === 'success' && setLocalFollowed(!localFollowed))
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    setLocalFollowed(followed)
  }, [followed])

  if (Number(userId) === followId) return null
  if (!Number(userId)) return null

  return (
    <PhatButton
      className={className}
      disabled={!userId || loading}
      onClick={handleToggleFollow}
      color={localFollowed ? undefined : 'green'}
      icon={compact ? undefined : localFollowed ? 'check' : 'plus'}>
      {localFollowed ? 'Unfollow' : 'Follow'}
    </PhatButton>
  )
}

export default FollowButton
